import { Modal } from '@veneer/core';
import styled from 'styled-components';

export const ModalTool = styled(Modal)`
  .vn-modal--dialog .vn-modal--content {
    max-width: 95vw;
    max-height: 95vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;
